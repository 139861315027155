import { createClient, createImageUrlBuilder, createPortableTextComponent, createPreviewSubscriptionHook } from 'next-sanity';

const config = {
  projectId: '9g8ar69c',
  dataset: 'production',
  apiVersion: '2021-03-25',
  useCdn: true
};

export const sanityClient = createClient(config);

export const usePreviewSubscription = createPreviewSubscriptionHook(config);

export const urlFor = (source) => createImageUrlBuilder(config).image(source);

export const PortableText = createPortableTextComponent({
  ...config,
  serializers: {}
});


