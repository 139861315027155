/* eslint-disable @next/next/no-img-element */
import navStyles from '../../styles/navigation/Nav.module.scss';
import { useState } from 'react';

import ToggleButtonStyles from '../../styles/navigation/ToggleButtonStyles.module.scss';
import Link from 'next/link';

import Spacer from '../elements/Spacer';

import MenuOverlayStyles from '../../styles/navigation/MenuOverlayStyles.module.scss';

import { PortableText } from '../../lib/sanity';
import OverlayMessage from './OverlayMessage';
import SocialIcons from '../elements/SocialIcons';

import NavMenuAccordion from './NavMenuAccordion';
import { useEffect } from 'react';

function Nav({ CompanyInfo, childcareNum = null, Nurseries }) {
  const [isOpen, setIsOpen] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const [navClosed, setNavClosed] = useState(true);

  const toggle = () => setIsOpen(!isOpen);

  if (typeof window !== 'undefined') {
    const changeBackground = () => {
      {
        window.scrollY >= 80 ? setNavbar(true) : setNavbar(false);
      }
    };
    window.addEventListener('scroll', changeBackground);
  }

  return (
    <>
      <div className={navbar ? 'navHolder active' : 'navHolder'} style={{ backgroundImage: 'url(/images/static/blue-repeat-pattern.svg)' }}>
        <nav className={navStyles.navHeader}>
          <div className={`${ToggleButtonStyles.button} togglebutton`}>
            <button className={isOpen ? 'hamburger hamburger--squeeze is-active' : 'hamburger hamburger--squeeze'} aria-pressed={isOpen ? 'true' : 'false'} aria-expanded={isOpen ? 'true' : 'false'} onClick={toggle} type="button" aria-label="Mobile Menu Toggle Button" role="button">
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </div>
          {/*
        // =====================================================================
        // OVERLAY
        // ===================================================================== */}

          <div className={isOpen ? 'OverlayStretch' : 'OverlayStretch hidden'}>
            <div className={MenuOverlayStyles.fullsheet}>
              <div className={MenuOverlayStyles.linksContainer}>
                <div>
                  <NavMenuAccordion CompanyInfo={CompanyInfo} navbar={navbar} isOpen={isOpen} setIsOpen={setIsOpen} Nurseries={Nurseries} />
                  <Spacer mt={3} />
                  {CompanyInfo.message && <OverlayMessage CompanyInfo={CompanyInfo} />}
                  <Spacer mt={2} />
                  <SocialIcons />
                </div>
              </div>
            </div>
          </div>

          {/*
        // =====================================================================
        // Left Links
        // ===================================================================== */}
          <div className={`${navStyles.leftLinks} leftLinks`}>
            <Link href="mailto:hello@more2nurseries.com">
              <button className={navStyles.mailTo}>hello@more2nurseries.com</button>
            </Link>
            <Link href={`tel:${!childcareNum ? '020 8305 0558' : '020 8853 3644'}`}>
              <button className={navStyles.telTo}>{!childcareNum ? '020 8305 0558' : '020 8853 3644'}</button>
            </Link>
          </div>
          {/*
        // =====================================================================
        // Logo
        // ===================================================================== */}
          <div>
            <Link href="/">
              <img src="/images/static/more2-logo.svg" alt="More2 ChildCare Logo" className={`${navStyles.more2_logo} more2logo`} />
            </Link>
          </div>
        </nav>
      </div>
    </>
  );
}

export default Nav;
